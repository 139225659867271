import React from "react";
// import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Clients() {

  // useEffect(()=>{
  //     document.title = 'Elixir | Our Clients';
  //   }, []);

  return (
    <>
    <section className="innerPages">
      <Container>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box1">
                <h1>Elixir & AR <span>Clients</span></h1>
                <h4>Empowering Clients with Innovation and Quality</h4>
              </div>
            </Col>
            <Col lg={6}>
              <div className="innerPages-box2">
                <div className="aboutPages-box2">
                  <img src={require('../assets/img/clients-page-banner-1.jpg')} className="img-fluid" alt="elixir" />
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="innerPages-box3">
              <div className="contactForm-form">
                  <Row><h4>Quick Responce</h4></Row>  
                    <Form className="mt-2">
                      <Row className="mb-4">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Control type="text" placeholder="Mobile" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control as="textarea" rows={3} placeholder="Message" />
                        </Form.Group>
                      </Row>
                      <Row>
                        <div className="text-end">
                          <Button className="submitBtn" type="submit">
                            Submit
                          </Button>  
                        </div>   
                      </Row>             
                    </Form>
                </div>
              </div>
            </Col>
          </Row>
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box4">
                <div className="contactMap-text">
                  <h3>Transforming Ideas into Quality Prints for Our Clients.</h3>
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <div className="clientPages-box5">
                <Row>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-1.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-2.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-3.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-4.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-5.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-6.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-7.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-8.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-9.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-10.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-11.png')} className="img-fluid" alt="elixir" />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <img src={require('../assets/img/client-logo-12.png')} className="img-fluid" alt="elixir" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    </>
  );
}

export default Clients;
