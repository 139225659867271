import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const ImageModal = () => {
  useEffect(() => {
    const modalElement = document.getElementById("imageModal");

    if (modalElement) {
      modalElement.addEventListener("hide.bs.modal", () => {
        const iframe = modalElement.querySelector("iframe");
        if (iframe) {
          iframe.src = ""; // Clear the iframe source to stop the video
        }
      });

      modalElement.addEventListener("show.bs.modal", () => {
        const iframe = modalElement.querySelector("iframe");
        if (iframe) {
          iframe.src = "https://www.youtube.com/embed/ZiT5EZBVxWM?autoplay=0&rel=0";
        }
      });
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hide.bs.modal", () => {});
        modalElement.removeEventListener("show.bs.modal", () => {});
      }
    };
  }, []);

  return (
    <div
      className="modal fade snapshotsModal"
      id="imageModal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center">
            <iframe
              width="100%"
              height="425"
              src="https://www.youtube.com/embed/ZiT5EZBVxWM?autoplay=0&rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};


function Signageindoor() {
  // useEffect(()=>{
  //   document.title = 'Elixir | Signage - Indoor';
  // }, []);

  return (
    <>
    <section className="innerPages">
      <Container>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box1">
                <h1>Elixir & AR <span>Signage Indoor</span></h1>
                <h4>Eye-Catching Custom Indoor Signage for Businesses</h4>
              </div>
            </Col>
            <Col lg={6}>
              <div className="innerPages-box2">
                <div className="servicesInner-content">                  
                  <Row>
                    <h4>Transform with <span>Stunning Indoor Signage</span></h4>
                    <Col md={12}>
                      <p>Enhance your space with vibrant, customizable indoor signage that grabs attention and communicates your brand effectively. Perfect for retail stores, offices, events, and more. Durable, stylish, weather-resistant, and designed to leave a lasting impression effortlessly.</p>   
                      <p>Elevate your interiors with premium indoor signage that captivates and communicates. Designed for retail, offices, and events, our signage is durable, customizable, and crafted to highlight your brand's identity in any setting. With unmatched quality, vibrant designs, and expert craftsmanship, make a bold statement that truly stands out.</p>                  
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={3}> 
              <div className="innerPages-box3">      
                <div className="contactForm-form">
                  <Row><h4>Snapshots</h4></Row>  
                  <div className="snapshotsImg" data-bs-toggle="modal" data-bs-target="#imageModal">
                    <img src={require('../assets/img/snapshots-img-1.jpg')} className="img-fluid snapshotsImg-img" alt="elixirworld" />
                    <div  className="snapshotsImg-icon">
                      <img src={require('../assets/img/youtube-red-icon.svg')} className="img-fluid" alt="elixirworld" />
                    </div>
                  </div>
                  <ImageModal />
                </div>
              </div>     
            </Col>
          </Row>
        </div>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box4">  
                <ul className="recentproLinks">
                  <hr />
                  <li>
                    <Link to="/Branding1">Digital Displays</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">LED Signs</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">Channel Letters</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">Wall Graphics</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">Floor Decals</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">Menu Boards</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">Acrylic Signs</Link>
                  </li>

                  <li>
                    <Link to="/Branding1">Retail Signage</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">Hospitality Signs</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">Industrial Safety Signs</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">Corporate Branding</Link>
                  </li>
                  <li>
                    <Link to="/Branding1">Real Estate Signs</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={9}>            
            <div className="aboutPages-box5">     
              <div className="servicesInner-content">                
                <Row>
                  <h4>Recent <span>Works</span></h4>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="latestWork-box">
                      <Link to="/Branding1">
                        <img src={require('../assets/img/work/work-img1.jpg')} className="img-fluid" alt="elixirworld"  />
                        <div class="overlay">
                          <div class="text">
                            <h4>BEST</h4>
                            <p>Brand Identity</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="latestWork-box">
                      <Link to="/Branding1">
                        <img src={require('../assets/img/work/work-img2.jpg')} className="img-fluid" alt="elixirworld"  />
                        <div class="overlay">
                          <div class="text">
                            <h4>BEST</h4>
                            <p>Brand Identity</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="latestWork-box">
                      <Link to="/Branding1">
                        <img src={require('../assets/img/work/work-img3.jpg')} className="img-fluid" alt="elixirworld"  />
                        <div class="overlay">
                          <div class="text">
                            <h4>BEST</h4>
                            <p>Brand Identity</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="latestWork-box">
                      <Link to="/Branding1">
                        <img src={require('../assets/img/work/work-img4.jpg')} className="img-fluid" alt="elixirworld"  />
                        <div class="overlay">
                          <div class="text">
                            <h4>BEST</h4>
                            <p>Brand Identity</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="latestWork-box">
                      <Link to="/Branding1">
                        <img src={require('../assets/img/work/work-img5.jpg')} className="img-fluid" alt="elixirworld"  />
                        <div class="overlay">
                          <div class="text">
                            <h4>BEST</h4>
                            <p>Brand Identity</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="latestWork-box">
                      <Link to="/Branding1">
                        <img src={require('../assets/img/work/work-img6.jpg')} className="img-fluid" alt="elixirworld"  />
                        <div class="overlay">
                          <div class="text">
                            <h4>BEST</h4>
                            <p>Brand Identity</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="latestWork-box">
                      <Link to="/Branding1">
                        <img src={require('../assets/img/work/work-img7.jpg')} className="img-fluid" alt="elixirworld"  />
                        <div class="overlay">
                          <div class="text">
                            <h4>BEST</h4>
                            <p>Brand Identity</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="latestWork-box">
                      <Link to="/Branding1">
                        <img src={require('../assets/img/work/work-img8.jpg')} className="img-fluid" alt="elixirworld"  />
                        <div class="overlay">
                          <div class="text">
                            <h4>BEST</h4>
                            <p>Brand Identity</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            </Col>
          </Row>
        </div>
      </Container>            
    </section>
    </>
  );
}

export default Signageindoor;