import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import rightWhatsapp from '../assets/img/whatsapp-icon.png';
import footerFacebook from '../assets/img/facebook-theme-icon.svg';
import footeryoutube from '../assets/img/youtube-theme-icon.svg';
import footerinstagram from '../assets/img/instagram-theme-icon.svg';

function Footer() {
  return (
    <>
    <section className="footerServices">
      <Container>
        <Row>
          <Col lg={12}>
            <h2 data-aos="fade-up" data-aos-duration="3000">A FULL <span>SERVICE</span></h2> 
          </Col>
          <Col xs={6} md={4} lg data-aos="fade-up" data-aos-duration="3000">
            <h4>
              <Link to="#">Design</Link>
            </h4>
            <ul>
              <li>
                <Link to="#">Logo Design</Link>
              </li>
              <li>
                <Link to="#">Brand Identity</Link>
              </li>
              <li>
                <Link to="#">Brand Auditing</Link>
              </li>
              <li>
                <Link to="#">Brochure Design</Link>
              </li>
              <li>
                <Link to="#">Product Design</Link>
              </li>
              <li>
                <Link to="#">Annual Reports</Link>
              </li>
              <li>
                <Link to="#">Print Services</Link>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={4} lg data-aos="fade-up" data-aos-duration="3000">
            <h4>
              <Link to="#">Signage</Link>
            </h4>
            <ul>
              <li>
                <Link to="#">Digital Displays</Link>
              </li>
              <li>
                <Link to="#">LED Signs</Link>
              </li>
              <li> 
                <Link to="#">Channel Letters</Link>
                </li>
              <li>
                <Link to="#">Wall Graphics</Link>
                </li>
              <li>
                <Link to="#">Floor Decals</Link>
                </li>
              <li>
                <Link to="#">Menu Boards</Link>
                </li>
              <li>
                <Link to="#">Acrylic Signs</Link>
                </li>
              <li>
                <Link to="#">Retail Signage</Link>
                </li>
              <li>
                <Link to="#">Hospitality Signs</Link>
                </li>
              <li>
                <Link to="#">Industrial Safety Signs</Link>
                </li>
              <li>
                <Link to="#">Corporate Branding</Link>
              </li>
              <li>
                <Link to="#">Real Estate Signs</Link>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={4} lg data-aos="fade-up" data-aos-duration="3000">
            <h4>
              <Link to="#">Exhibitions</Link>
            </h4>
            <ul>
              <li>
                <Link to="#">Theme Conceptualization</Link>
              </li>
              <li>
                <Link to="#">Trade Show Technology</Link>
              </li>
              <li> 
                <Link to="#">Digital Event Services</Link>
                </li>
              <li>
                <Link to="#">Photo and Videography</Link>
                </li>
              <li>
                <Link to="#">Live Streaming</Link>
                </li>
              <li>
                <Link to="#">Event Planning</Link>
                </li>
              <li>
                <Link to="#">Award Ceremonies</Link>
                </li>
              <li>
                <Link to="#">Exhibition Promotion</Link>
                </li>
              <li>
                <Link to="#">Product Launches</Link>
                </li>
              <li>
                <Link to="#">Booth/Stand Design</Link>
                </li>
              <li>
                <Link to="#">Event Advertising</Link>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={4} lg data-aos="fade-up" data-aos-duration="3000">
            <h4><Link to="#">Digital & Website</Link></h4>
            <ul>
              <li>
                <Link to="#">Website Design</Link>
              </li>
              <li>
                <Link to="#">Mobile Apps</Link>
              </li>
              <li>
                <Link to="#">Content Management System</Link>
              </li>
              <li>
                <Link to="#">E-commerce Solutions</Link>
              </li>
              <li>
                <Link to="#">Custom Web Applications</Link>
              </li>
              <li>
                <Link to="#">Social Media Management</Link>
              </li>
              <li>
                <Link to="#">Analytics & Performance Tracking</Link>
              </li>
              <li>
                <Link to="#">Email Marketing</Link>
              </li>
              <li>
                <Link to="#">Branding & Graphic Design</Link>
                </li>
              <li>
                <Link to="#">Online Marketing</Link>
              </li>
            </ul>
          </Col>  
          <Col xs={6} md={4} lg data-aos="fade-up" data-aos-duration="3000">
            <h4><Link to="#">General Trading</Link></h4>
            <ul>
              <li>
                <Link to="#">Printing Equipment</Link>
              </li>
              <li>
                <Link to="#">Digital Printing Machines</Link>
              </li>
              <li>
                <Link to="#">UV Printing Machines</Link>
              </li>
              <li>
                <Link to="#">Packaging Printing Equipment</Link>
              </li>
              <li>
                <Link to="#">Technical Support Services</Link>
              </li>
              <li>
                <Link to="#">Spare Parts & Consumables</Link>
              </li>
              <li>
                <Link to="#">Used Printing Machinery Sales</Link>
              </li>
            </ul>  
          </Col>
        </Row>
      </Container>
    </section>
    <section className="leftWhatsapp">
      <Link to="https://wa.me/966552301809?text=Hello%2C%20I%27d%20like%20to%20chat!" target="_blank" rel="noopener noreferrer">
        <img src={rightWhatsapp} className="img-fluid" alt="elixir" />
      </Link>
    </section>
    <footer>
    <Container>
      <Row>
        <Col lg="3" md={3}>
          <div className="footerLink">
            <h4><span>Links</span></h4>
            <Link to="/aboutus">About</Link>
            <Link to="/qualitypolicy">Quality Policy</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/clients">Clients</Link>
            <Link to="/careers">Careers</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </Col>
        <Col lg="6" md={9}>
          <Row>
          <Col md={12}>
            <div className="footerLink">  
              <h4><span>Sales</span></h4>
            </div>
          </Col>
          <Col md={6}>
            <div className="footerAddr">    
              <h5>SAUDI ARABIA</h5>
              <p>Mobile: <Link to="tel:+966552301809">+966 55 230 1809</Link></p>  
            </div>     
          </Col>
          <Col md={6}>
            <div className="footerAddr mb-3">    
              <h5>INDIA</h5>
              <p>Mobile: <Link to="tel:+9163851 10628">+91 63851 10628</Link></p>  
            </div>        
          </Col>
          <Col md={12}>
            <div className="footerLink">  
              <h4><span>Locations</span></h4>
            </div>
          </Col>
            <Col md={6}>
              <div className="footerAddr">    
                <h5>SAUDI ARABIA</h5>            
                <p>P.O.Box:2834, Alkhobar 31952,</p>
                <p>Dammam 31463,</p>
                <p>Saudi Arabia.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="footerAddr">    
                <h5>INDIA</h5>            
                <p>AK Tower, 139 A,</p>
                <p>Velachery Road,</p>
                <p>Little Mount, Chennai</p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg="3" md={12}>
          <div className="footerSocial">
            <h4><span>Social</span></h4>
            <Link to="https://www.facebook.com/people/ElixirWorldWide/100069599795667/" target="_blank">
              <img src={footerFacebook} className="img-fluid" alt="elixir" />
            </Link>
            {/* <Link to="https://x.com/i/flow/login?redirect_after_login=%2Felixirworldwide" target="_blank">
              <img src={footertwitter} className="img-fluid" alt="elixir" />
            </Link> */}
            <Link to="/" target="_blank">
              <img src={footerinstagram} className="img-fluid" alt="elixir" />
            </Link>
            <Link to="https://www.youtube.com/elixirworldwide" target="_blank">
              <img src={footeryoutube} className="img-fluid" alt="elixir" />
            </Link>
            <p><span>Enquiry:</span> <Link to="mailto:anand@elixirworldwide.com">anand@elixirworldwide.com</Link></p>
            <p><span>Mail:</span> <Link to="mailto:elixirworldwide@gmail.com">elixirworldwide@gmail.com</Link></p>
            <p><span>Job:</span> <Link to="mailto:jobs@elixirworldwide.com">jobs@elixirworldwide.com</Link></p>
            <p className="privacyLink"><Link to="/">Privacy Policy</Link></p>
            <p className="footerCopyright">© ELIXIR Advertising & AR Graphics</p>
          </div>
        </Col>
      </Row>
    </Container>
    </footer>       
    </>
  );
}

export default Footer;
