import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/style.css";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Navigation, Footer, Home, Aboutus, Contact, Brandingandgraphicdesign, Signageoutdoor, Signageindoor, Signageindustrial, Projects, Facility, Qualitypolicy, Clients, Jobs, Printingservices, Eventservices, Exhibitionservices, Corporateservices, Webdesignservices, Socialmediamarketing, Tradingservices, Branding1 } from "./components";
import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init();

ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      {/* <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<Aboutus />} />      
      <Route path="/digitalconsultancy" element={<Digitalconsultancy />} />
      <Route path="/project" element={<Project />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/contact" element={<Contact />} /> */}

      {/* <Route path="/elixir-new" element={<Home />} />
      <Route path="/elixir-new/aboutus" element={<Aboutus />} />      
      <Route path="/elixir-new/brandingandgraphicdesign" element={<Brandingandgraphicdesign />} />
      <Route path="/elixir-new/signageoutdoor" element={<Signageoutdoor />} />
      <Route path="/elixir-new/signageindoor" element={<Signageindoor />} />
      <Route path="/elixir-new/signageindustrial" element={<Signageindustrial />} />

      <Route path="/elixir-new/printingservices" element={<Printingservices />} />
      <Route path="/elixir-new/eventservices" element={<Eventservices />} />
      <Route path="/elixir-new/exhibitionservices" element={<Exhibitionservices />} />
      <Route path="/elixir-new/corporateservices" element={<Corporateservices />} />
      <Route path="/elixir-new/webdesignservices" element={<Webdesignservices />} />
      <Route path="/elixir-new/Socialmediamarketing" element={<Socialmediamarketing />} />
      <Route path="/elixir-new/tradingservices" element={<Tradingservices />} />

      <Route path="/elixir-new/projects" element={<Projects />} />
      <Route path="/elixir-new/facility" element={<Facility />} />
      <Route path="/elixir-new/qualitypolicy" element={<Qualitypolicy />} />
      <Route path="/elixir-new/clients" element={<Clients />} />
      <Route path="/elixir-new/jobs" element={<Jobs />} />
      <Route path="/elixir-new/contact" element={<Contact />} />
      <Route path="/elixir-new/branding1" element={<Branding1 />} />
      <Route path="*" element={<Navigate to="/elixir-new" />} /> */}

<Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<Aboutus />} />      
      <Route path="/brandingandgraphicdesign" element={<Brandingandgraphicdesign />} />
      <Route path="/signageoutdoor" element={<Signageoutdoor />} />
      <Route path="/signageindoor" element={<Signageindoor />} />
      <Route path="/signageindustrial" element={<Signageindustrial />} />

      <Route path="/printingservices" element={<Printingservices />} />
      <Route path="/eventservices" element={<Eventservices />} />
      <Route path="/exhibitionservices" element={<Exhibitionservices />} />
      <Route path="/corporateservices" element={<Corporateservices />} />
      <Route path="/webdesignservices" element={<Webdesignservices />} />
      <Route path="/Socialmediamarketing" element={<Socialmediamarketing />} />
      <Route path="/tradingservices" element={<Tradingservices />} />

      <Route path="/projects" element={<Projects />} />
      <Route path="/facility" element={<Facility />} />
      <Route path="/qualitypolicy" element={<Qualitypolicy />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/branding1" element={<Branding1 />} />
      <Route path="*" element={<Navigate to="/" />} />

    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);

