import React from "react";
// import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Qualitypolicy() {

  // useEffect(()=>{
  //     document.title = 'Elixir | Our Qualitypolicy';
  //   }, []);

  return (
    <>
    <section className="innerPages">
      <Container>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box1">
                <h1>Elixir & AR <span>Quality Policy</span></h1>
                <h4>Innovation. Precision. Excellence in Printing</h4>
              </div>
            </Col>
            <Col lg={6}>
              <div className="innerPages-box2">
                <div className="aboutPages-box2">
                  <img src={require('../assets/img/qualitypolicy-page-banner-1.jpg')} className="img-fluid" alt="elixir" />
                </div>
              </div>
            </Col>
            <Col lg={3}>
            <div className="innerPages-box3">      
                <div className="contactForm-form">
                  <Row><h4>Quick Responce</h4></Row>  
                    <Form className="mt-2">
                      <Row className="mb-4">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Control type="text" placeholder="Mobile" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control as="textarea" rows={3} placeholder="Message" />
                        </Form.Group>
                      </Row>
                      <Row>
                        <div className="text-end">
                          <Button className="submitBtn" type="submit">
                            Submit
                          </Button>  
                        </div>   
                      </Row>             
                    </Form>
                </div>
              </div> 
            </Col>
          </Row>
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box4">
                <div className="contactMap-text">
                  <h3>Printing Excellence, Powered by Innovation, Quality.</h3>
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <div className="aboutPages-box5">
                <h4>Elixir Worldwide Quality Policy</h4>
                <p>At Elixir Worldwide, we are committed to delivering superior printing solutions through innovation, precision, and reliability. We ensure the highest standards in our products and services by adhering to strict quality controls, continuous improvement, and customer satisfaction. Our dedication to excellence drives us to provide cutting-edge technology and exceptional support, helping businesses achieve flawless printing results.</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    </>
  );
}

export default Qualitypolicy;
