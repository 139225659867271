import React from "react";
// import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Facility() {

  // useEffect(()=>{
  //     document.title = 'Elixir | Our Facility';
  //   }, []);

  return (
    <>
    <section className="innerPages">
      <Container>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box1">
                <h1>Elixir & AR <span>Facility</span></h1>
                <h4>Powering Your Prints, Elevating Your Business</h4>
              </div>
            </Col>
            <Col lg={6}>
              <div className="innerPages-box2">
                <div className="aboutPages-box2">
                  <img src={require('../assets/img/facility-page-banner-1.jpg')} className="img-fluid" alt="elixir" />
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="innerPages-box3">      
                <div className="contactForm-form">
                  <Row><h4>Quick Responce</h4></Row>  
                    <Form className="mt-2">
                      <Row className="mb-4">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Control type="text" placeholder="Mobile" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control as="textarea" rows={3} placeholder="Message" />
                        </Form.Group>
                      </Row>
                      <Row>
                        <div className="text-end">
                          <Button className="submitBtn" type="submit">
                            Submit
                          </Button>  
                        </div>   
                      </Row>             
                    </Form>
                </div>
              </div> 
            </Col>
          </Row>
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box4">
                <div className="contactMap-text">
                  <h3>Precision in Every Print, Quality in Every Machine.</h3>
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <div className="aboutPages-box5">
                <p>Elixir Worldwide is a leading provider of high-quality printing solutions, offering cutting-edge printing machines and consumables to businesses around the globe. With a commitment to innovation, efficiency, and reliability, we empower industries with state-of-the-art technology that delivers precision and performance. Whether for commercial, industrial, or specialized printing needs, Elixir Worldwide ensures top-tier products and unmatched customer support.</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    </>
  );
}

export default Facility;
