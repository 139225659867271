import React from "react";
// import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import log01 from '../assets/img/logo.png';

function Aboutus() {

  // useEffect(()=>{
  //   document.title = 'Elixir | About Us';
  // }, []);

  return (
    <>
    <section className="innerPages">
      <Container>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box1">
                <h1>Elixir & AR <span>About Us</span></h1>
                <h4>We Help Your Business With Creative Idea</h4>
              </div>
            </Col>
            <Col lg={6}>
              <div className="innerPages-box2">
                <div className="aboutPages-box2">
                  <img src={require('../assets/img/about-page-banner-1.jpg')} className="img-fluid" alt="elixir" />
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="innerPages-box3">
                <div className="aboutPages-logo">
                  <h4>Leading Company</h4>
                  <img id="one" src={log01} className="img-fluid" alt="Elixir" />
                </div>
              </div>
            </Col>
          </Row>
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box4">
                <div className="contactMap-text">
                  <h3>Our Commitment to Quality and Innovation.</h3>
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <div className="aboutPages-box5">
                <p>Established in 2010, Elixir & AR Graphics has proudly completed 15 years of delivering exceptional services in Saudi Arabia. As a leading company, we specialize in branding, graphic design, signage, printing, event management, exhibitions, corporate gifts, web design, and trading. Our commitment to quality and innovation has made us a trusted partner for diverse industries. With a proven track record, we continue to set benchmarks in creativity and professional excellence.</p>
                <hr />
                <h4>Mission</h4>
                <p>At <strong>Elixir & AR Graphics</strong>, our mission is to revolutionize the way brands, businesses, and individuals connect with their audiences through cutting-edge Augmented Reality (AR) and visually stunning graphic designs. We are driven by the passion to create immersive, engaging, and impactful experiences that leave a lasting impression.</p>
                <h4>Vision</h4>
                <p>At <strong>Elixir & AR Graphics</strong>, we envision a world where creativity, technology, and connection converge to reshape how people experience design and storytelling. We aspire to lead the way in transforming traditional visual communication into immersive, interactive, and unforgettable experiences.</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    </>
  );
}

export default Aboutus;
