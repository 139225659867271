import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function Brandingandgraphicdesign() {
  return (
    <>
      <section className="innerPages">
      <Container>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box1">
                <h1>Elixir & AR <span>Branding & Graphic</span></h1>
                <h4>Designing Your Brand Story Concept to Creation</h4>
              </div>
            </Col>
            <Col lg={6}>
              <div className="innerPages-box2">
                <div className="servicesInner-content">                  
                  <Row>
                    <h4>Creative Branding & <span>Graphic Design</span></h4>
                    <Col md={12}>
                      <p>In today’s competitive market, crafting a memorable brand story is essential for connecting with your audience and standing out from the crowd. Elixir, a leader in innovative branding solutions, presents the ultimate guide for businesses, entrepreneurs, and creatives looking to define and elevate their brand identity.</p>   
                      <p>Transforming ideas into stunning visuals, we design unique logos, brand identities, and promotional graphics. Whether launching a business or reimagining your brand, we craft bold, memorable designs!</p>                  
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={3}> 
              <div className="innerPages-box3">      
              <div className="contactForm-form">
                  <Row><h4>TALK TO US</h4></Row>  
                    <Form className="mt-2">
                      <Row className="mb-4">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Control type="text" placeholder="Mobile" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control as="textarea" rows={3} placeholder="Message" />
                        </Form.Group>
                      </Row>
                      <Row>
                        <div className="text-end">
                          <Button className="submitBtn" type="submit">
                            Submit
                          </Button>  
                        </div>   
                      </Row>             
                    </Form>
                </div>
              </div>     
            </Col>
          </Row>
        </div>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box4">  
                <ul className="recentproLinks">
                  <hr />
                  <li>
                    <Link to="#">Logo Design</Link>
                  </li>
                  <li>
                    <Link to="#">Brand Identity</Link>
                  </li>
                  <li>
                    <Link to="#">Brand Auditing</Link>
                  </li>
                  <li>
                    <Link to="#">Brochure Design</Link>
                  </li>
                  <li>
                    <Link to="#">Product Design</Link>
                  </li>
                  <li>
                    <Link to="#">Annual Reports</Link>
                  </li>
                  <li>
                    <Link to="#">Print Services</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={9}>            
            <div className="aboutPages-box5 serviceWhite">     
              <div className="servicesInner-content">     
                <Row>
                  <Col lg={6} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="servicesInner-box1">
                      <img src={require('../assets/img/branding-img-1.jpg')} className="img-fluid" alt="elixirworld"  />
                      <div class="overlay">
                        <div class="text">
                          <h4>Design and Branding Agency</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div class="servicesInner-box2">
                      <img src={require('../assets/img/work/work-img1.jpg')} className="img-fluid" alt="elixirworld"  />
                      <div class="overlay">
                        <div class="text">
                          <h4>Logo</h4>
                        </div>
                      </div>
                    </div>
                    <div class="servicesInner-box2">
                      <img src={require('../assets/img/work/work-img1.jpg')} className="img-fluid" alt="elixirworld"  />
                      <div class="overlay">
                        <div class="text">
                          <h4>Brand</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            </Col>
          </Row>
        </div>
      </Container>            
    </section>
    </>
  );
}

export default Brandingandgraphicdesign;
