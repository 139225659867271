import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ScrollToTop from "./ScrollToTop";
import log01 from '../assets/img/logo.png';
import rightFacebook from '../assets/img/facebook-icon.svg';
import rightTwitter from '../assets/img/twitter-icon.svg';
import rightYoutube from '../assets/img/youtube-icon.svg';
import downloadepdficon from '../assets/img/download-pdf-icon.svg';
import rightInstagram from '../assets/img/instagram-icon.svg';
import menuphone from '../assets/img/menu-phone-icon.svg';
import menuwhatsapp from '../assets/img/menu-whatsapp-icon.svg';
import menumail from '../assets/img/menu-mail-icon.svg';
import brochurePdf1 from '../assets/img/pdf/elixir-brochur.pdf';
import brochurePdf2 from '../assets/img/pdf/elixir-safety.pdf';
import brochurePdf3 from '../assets/img/pdf/elixir-arsign.pdf';
import brochurePdf4 from '../assets/img/pdf/elixir-printing.pdf';
import brochurePdf5 from '../assets/img/pdf/elixir-gifts.pdf';
import brochurePdf6 from '../assets/img/pdf/elixir-exhibitions.pdf';
import brochurePdf7 from '../assets/img/pdf/elixir-packaging.pdf';
import brochurePdf8 from '../assets/img/pdf/elixir-branding.pdf';


function Navigation() {

  // Modal Start
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Modal End

  const [navbar, setNavbar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [expanded, setExpanded] = useState(false); // State to manage Navbar.Toggle

  // Toggle navbar background on scroll
  const changeBackground = () => {
    setNavbar(window.scrollY >= 10);
  };

  // Track window resizing to detect mobile or tablet views
  const updateView = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    window.addEventListener('resize', updateView);

    return () => {
      window.removeEventListener('scroll', changeBackground);
      window.removeEventListener('resize', updateView);
    };
  }, []);

  // Toggle dropdown on click for mobile view
  const handleDropdownToggle = () => {
    if (isMobile) setShowDropdown(!showDropdown);
  };

  // Collapse Navbar when a NavLink is clicked
  const handleNavClick = () => {
    setExpanded(false);
  };

// Dropdown Active Start
  const location = useLocation(); // Get the current location

  // Check if the current path matches any of the Services submenu paths
  const isServicesActive = [
    "/brandingandgraphicdesign",
    "/signageoutdoor",
    "/signageindoor",
    "/signageindustrial",
    "/printingservices",
    "/eventservices",
    "/exhibitionservices",
    "/webdesignservices",
    "/socialmediamarketing",
    "/Corporateservices",
    "/Tradingservices",
  ].some((path) => location.pathname === path);
// Dropdown Active End  

  return (
    <div className="navigation fixed-top">
      <ScrollToTop />
      <Navbar expand="lg" expanded={expanded} className={navbar ? 'navbar active' : 'navbar'}>
        <Container>
          {/* <Row> */}
            <Navbar.Brand href="#home" className="me-auto row">
              <Link to="/elixir-new">
                <img id="one" src={log01} alt="Elixir" />
                <img id="two" src={log01} alt="Elixir" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(expanded ? false : true)}
            />
            <Navbar.Collapse id="basic-navbar-nav" className="me-auto m-auto">
              <Nav className="ms-auto navmenuCenter">
                <NavLink className="nav-link" to="/elixir-new" onClick={handleNavClick} end>
                  Home
                </NavLink>
                <NavLink className="nav-link" to="/aboutus" onClick={handleNavClick} end>
                  About
                </NavLink>
                <NavDropdown
                  className={`dropdown ${isServicesActive ? "active" : ""}`}
                  title="Services"
                  onClick={handleDropdownToggle} end
                >
                  <NavDropdown.Item
                    as={Link}
                    className="dropdown-item"
                    to="/brandingandgraphicdesign"
                    onClick={handleNavClick} end
                  >
                    Branding & Graphic Design
                  </NavDropdown.Item>
                  <NavDropdown title="Signage" className="dropend serviceMenu" end> 
                    <NavDropdown.Item
                      className="dropdown-item"
                      as={Link}
                      to="/signageoutdoor"
                      onClick={handleNavClick}
                    >
                      - Outdoor
                    </NavDropdown.Item>       
                    <NavDropdown.Item
                      className="dropdown-item"
                      as={Link}
                      to="/signageindoor"
                      onClick={handleNavClick}
                    >
                      - Indoor
                    </NavDropdown.Item> 
                    <NavDropdown.Item
                      className="dropdown-item"
                      as={Link}
                      to="/signageindustrial"
                      onClick={handleNavClick}
                    >
                      - Industrial
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown.Item className="dropdown-item" as={Link} to="/printingservices" onClick={handleNavClick} end>Printing & Packaging</NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item" as={Link} to="/eventservices" onClick={handleNavClick}  end>Event Management</NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item" as={Link} to="/exhibitionservices" onClick={handleNavClick}  end>Exhibition Booths</NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item" as={Link} to="/webdesignservices" onClick={handleNavClick} end>Website Design & Developments</NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item" as={Link} to="/socialmediamarketing" onClick={handleNavClick} end>Social Media Marketing</NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item" as={Link} to="/corporateservices" onClick={handleNavClick} end>Corporate Gifts</NavDropdown.Item>                
                  <NavDropdown.Item className="dropdown-item" as={Link} to="/tradingservices" onClick={handleNavClick} end>General Trading</NavDropdown.Item>

                </NavDropdown>
                <NavLink className="nav-link" to="/projects" onClick={handleNavClick} end>
                  Projects
                </NavLink>
                <NavLink className="nav-link" to="/facility" onClick={handleNavClick} end>
                  Facility
                </NavLink>
                <NavLink className="nav-link" to="/qualitypolicy" onClick={handleNavClick} end>
                  Quality
                </NavLink>              
                <NavLink className="nav-link" to="/clients" onClick={handleNavClick} end>
                  Clients
                </NavLink>
                <NavLink className="nav-link" to="/jobs" onClick={handleNavClick} end>
                  Jobs
                </NavLink>
                <NavLink className="nav-link" to="/contact" onClick={handleNavClick} end>
                  Contact
                </NavLink>
                <NavDropdown
                  className={`dropdown`}
                  title="Quick Contact"
                  onClick={handleDropdownToggle} end
                >
                  <NavDropdown.Item className="dropdown-item" as={Link} to="tel:+966552301809" onClick={handleNavClick} end><img id="one" src={menuphone} alt="Elixir" target="_blank" /> +966 55 230 1809</NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item" as={Link} to="https://wa.me/966552301809?text=Hello%2C%20I%27d%20like%20to%20chat!" onClick={handleNavClick}  end><img id="one" src={menuwhatsapp} alt="Elixir"  target="_blank" /> +966 55 230 1809</NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item" as={Link} to="mailto:anand@elixirworldwide.com" onClick={handleNavClick}  end><img id="one" src={menumail} alt="Elixir"  target="_blank" /> anand@elixirworldwide.com</NavDropdown.Item>

                </NavDropdown>
              </Nav>
              <Link onClick={handleShow}
                className="ms-auto brochureBtn"
              >
                <img src={require('../assets/img/brochure-img-1.jpg')} className="img-fluid" alt="elixir"  />
              </Link>
            </Navbar.Collapse>
          {/* </Row> */}
        </Container>
      </Navbar>

      <section className="leftSocial" data-aos="zoom-in" data-aos-duration="3000">
        <Link to="https://www.facebook.com/people/ElixirWorldWide/100069599795667/" target="_blank" rel="noopener noreferrer">
          <img src={rightFacebook} alt="Facebook" />
        </Link>
        {/* <Link to="https://x.com/i/flow/login?redirect_after_login=%2Felixirworldwide" target="_blank" rel="noopener noreferrer">
          <img src={rightTwitter} alt="Twitter" />
        </Link> */}
        <Link to="/" target="_blank" rel="noopener noreferrer">
          <img src={rightInstagram} alt="YouTube" />
        </Link>
        <Link to="https://www.youtube.com/elixirworldwide" target="_blank" rel="noopener noreferrer">
          <img src={rightYoutube} alt="YouTube" />
        </Link>        
        <h4>Follow</h4>
      </section>

      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="brocureBox-h4">Brochur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="brocureBox">
            <a href={brochurePdf1} className="brocureBox-file" target="_blank">
              <div className="brocureBox-img">
                <img src={require('../assets/img/elixir-brochur-img-1.jpg')} className="brocureBox-img-main img-fluid" alt="elixir" />
                <img src={downloadepdficon} className="brocureBox-img-download" alt="elixir" />
              </div>
              <h4>Elixir Brochur</h4>
            </a>
            <a href={brochurePdf2} className="brocureBox-file" target="_blank">
              <div className="brocureBox-img">
                <img src={require('../assets/img/elixir-safety-img-1.jpg')} className="brocureBox-img-main img-fluid" alt="elixir" />
                <img src={downloadepdficon} className="brocureBox-img-download" alt="elixir" />
              </div>
              <h4>Elixir Safety</h4>
            </a>
            <a href={brochurePdf3} className="brocureBox-file" target="_blank">
              <div className="brocureBox-img">
                <img src={require('../assets/img/elixir-arsign-img-1.jpg')} className="brocureBox-img-main img-fluid" alt="elixir" />
                <img src={downloadepdficon} className="brocureBox-img-download" alt="elixir" />
              </div>
              <h4>Elixir AR Sign</h4>
            </a>            
            <a href={brochurePdf4} className="brocureBox-file" target="_blank">
              <div className="brocureBox-img">
                <img src={require('../assets/img/elixir-printing-img-1.jpg')} className="brocureBox-img-main img-fluid" alt="elixir" />
                <img src={downloadepdficon} className="brocureBox-img-download" alt="elixir" />
              </div>
              <h4>Elixir Printing</h4>
            </a>
            <a href={brochurePdf5} className="brocureBox-file" target="_blank">
              <div className="brocureBox-img">
                <img src={require('../assets/img/elixir-gifts-img-1.jpg')} className="brocureBox-img-main img-fluid" alt="elixir" />
                <img src={downloadepdficon} className="brocureBox-img-download" alt="elixir" />
              </div>
              <h4>Elixir Gifts</h4>
            </a>
            <a href={brochurePdf6} className="brocureBox-file" target="_blank">
              <div className="brocureBox-img">
                <img src={require('../assets/img/elixir-exhibitions-img-1.jpg')} className="brocureBox-img-main img-fluid" alt="elixir" />
                <img src={downloadepdficon} className="brocureBox-img-download" alt="elixir" />
              </div>
              <h4>Elixir Exhibitions</h4>
            </a>
            <a href={brochurePdf7} className="brocureBox-file" target="_blank">
              <div className="brocureBox-img">
                <img src={require('../assets/img/elixir-packaging-img-1.jpg')} className="brocureBox-img-main img-fluid" alt="elixir" />
                <img src={downloadepdficon} className="brocureBox-img-download" alt="elixir" />
              </div>
              <h4>Elixir Packaging</h4>
            </a>
            <a href={brochurePdf8} className="brocureBox-file" target="_blank">
              <div className="brocureBox-img">
                <img src={require('../assets/img/elixir-branding-img-1.jpg')} className="brocureBox-img-main img-fluid" alt="elixir" />
                <img src={downloadepdficon} className="brocureBox-img-download" alt="elixir" />
              </div>
              <h4>Elixir Branding</h4>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Navigation;