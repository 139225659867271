import React from "react";
// import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Contact() {

  // useEffect(()=>{
  //   document.title = 'Elixir | Contact Us';
  // }, []);

  return (
    <>
    <section className="innerPages">
      <Container>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box1">
                <h1>Elixir & AR <span>Contact Us</span></h1>
                <h4>Share a Little Spark of Inspiration with you!</h4>
              </div>
            </Col>
            <Col lg={6}>
              <div className="innerPages-box2">
                <div className="contactForm-address">                  
                  <Row>
                    <h4>Address</h4>
                    <Col md={6}>
                      <h5>SAUDI ARABIA</h5>
                      <p>P.O.Box:2834, Alkhobar 31952,</p>
                      <p>Dammam 31463, Saudi Arabia.</p>
                      <p><span>Mobile:</span> <a href="tel:+966552301809">+966 55 230 1809</a></p>                      
                    </Col>
                    <Col md={6}>
                      <h5>INDIA</h5>
                      <p>AK Tower, 139 A, Velachery Road,</p>
                      <p>Little Mount, Chennai - 600016.</p>
                      <p><span>Mobile:</span> <a href="tel:+916385110628">+91 63851 10628</a></p>                      
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <h4 className="mt-2">Mail</h4>
                      <p><span>Enquiry:</span> <a href="mailto:Anand@elixirworldwide.com">anand@elixirworldwide.com</a></p>
                      <p><span>Mail:</span> <a href="mailto:elixirworldwide@gmail.com">elixirworldwide@gmail.com</a></p>
                      <p><span>Job:</span> <a href="mailto:jobs@elixirworldwide.com">jobs@elixirworldwide.com</a></p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={3}> 
              <div className="innerPages-box3">      
                <div className="contactForm-form">
                  <Row><h4>Quick Responce</h4></Row>  
                    <Form className="mt-2">
                      <Row className="mb-4">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Control type="text" placeholder="Mobile" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Control as="textarea" rows={3} placeholder="Message" />
                        </Form.Group>
                      </Row>
                      <Row>
                        <div className="text-end">
                          <Button className="submitBtn" type="submit">
                            Submit
                          </Button>  
                        </div>   
                      </Row>             
                    </Form>
                </div>
              </div>     
            </Col>
          </Row>
        </div>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box4">   
                <div className="contactMap-text">
                  <h3>Where Innovation Meets Identity.</h3>
                </div>  
              </div>
            </Col>
            <Col lg={9}>            
            <div className="innerPages-box5">     
              <div className="contactMap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14308.395569932729!2d50.20320815452458!3d26.290893932369094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x20573b6ae577f8eb%3A0xf3ae2a2c0602950a!2sElixir%20Worldwide%20Advertising%20%26%20Printing%2C%20Signage!5e0!3m2!1sen!2sin!4v1741030686082!5m2!1sen!2sin" loading="lazy" referrerpolicy="no-referrer-when-downgrade" sandbox="allow-scripts allow-same-origin allow-popups" />
              </div>
            </div>
            </Col>
          </Row>
        </div>
      </Container>            
    </section>
    </>
  );
}

export default Contact;
