import React from "react";
// import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';

function Jobs() {

  // useEffect(()=>{
  //     document.title = 'Elixir | Our Jobs';
  //   }, []);

  return (
    <>
    <section className="innerPages">
      <Container>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box1">
                <h1>Elixir & AR <span>Our Jobs</span></h1>
                <h4>Join Elixir Worldwide: Innovate, Grow, Succeed</h4>
              </div>
            </Col>
            <Col lg={9}>
            <div className="projectPages-box5">               
                  <div className="jobBox">
                    <h4>Be Part of Our Company</h4>
                    <Accordion defaultActiveKey="">

                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="jobListing">
                            <h5>1. Print Designer</h5>
                            <p>Graphic Designer specializing in vector illustrations, print media.</p>
                            <div className="lable">
                              <span>1-5 Years</span>
                              <span>Full Time</span>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                        <div className="jobListing">
                          <h6>Job Description</h6>
                          <ul>
                            <li>1 to 5 years of experience in Photoshop</li>
                            <li>Creating logos, branding materials, brochures in Coraldraw</li>
                            <li>Designing flyers, posters, banners, business cards</li>
                            <li>Creating billboard, vehicle wraps, and signage designs.</li>
                          </ul>
                          <a href="mailto: jobs@elixirworldwide.com" className="applyBtn">Apply</a>
                        </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <div className="jobListing">
                            <h5>2. Vector Illustration Artist</h5>
                            <p>We are looking for mid-level Vector Illustration Artist to join our team.</p>
                            <div className="lable">
                              <span>6 Years</span>
                              <span>Full Time</span>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="jobListing">
                            <h6>Job Description</h6>
                            <ul>
                              <li>1 to 5 years of experience in Illustration</li>
                              <li>Creating logos, branding materials, brochures</li>
                              <li>Designing flyers, posters, banners, business cards</li>
                              <li>Creating billboard, vehicle wraps, and signage designs.</li>
                            </ul>
                            <a href="mailto: jobs@elixirworldwide.com" className="applyBtn">Apply</a>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <div className="jobListing">
                            <h5>3. Signage & Printing Designer</h5>
                            <p>We are looking for high-level Signage to join our team.</p>
                            <div className="lable">
                              <span>8 Years</span>
                              <span>Full Time</span>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="jobListing">
                            <h6>Job Description</h6>
                            <ul>
                              <li>8 years of experience in Photoshop</li>
                              <li>Creating logos, branding materials, brochures in Coraldraw</li>
                              <li>Designing flyers, posters, banners, business cards</li>
                              <li>Creating billboard, vehicle wraps, and signage designs.</li>
                            </ul>
                            <a href="mailto: jobs@elixirworldwide.com" className="applyBtn">Apply</a>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                    </Accordion>
                  </div>
                </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    </>
  );
}

export default Jobs;
