import React, { useState } from "react";
// import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Project() {

  // useEffect(()=>{
  //   document.title = 'Elixir | Our Projects';
  // }, []);

  const [key, setKey] = useState('home');

  return (
    <>
    <section className="innerPages">
      <Container>
        <div className="">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={3}>
              <div className="innerPages-box1">
                <h1>Elixir & AR <span>Projects</span></h1>
                <h4>Transforming Creative Projects and Innovation</h4>
              </div>
            </Col>
            <Col lg={9}>
              <div className="projectPages-box5">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"              
                >
                  <Tab eventKey="home" title="Digital">
                    <Row>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img1.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img2.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img3.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img4.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img5.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img6.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img7.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img8.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img9.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img10.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img11.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img12.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="exhibitions" title="Exhibitions">
                    <Row>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img1.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img2.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img3.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img4.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="events" title="Events">
                    <Row>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img1.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img2.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img3.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img4.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Tab>              
                  <Tab eventKey="printing" title="Printing">
                    <Row>
                    <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img1.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img2.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img3.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img4.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="websites" title="Websites">
                    <Row>
                    <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img1.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img2.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="3000">
                        <div class="latestWork-box">
                          <Link to="#">
                            <img src={require('../assets/img/work/work-img3.jpg')} className="img-fluid" alt="elixirworld"  />
                            <div class="overlay">
                              <div class="text">
                                <h4>BEST</h4>
                                <p>Brand Identity</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    </>
  );
}

export default Project;
